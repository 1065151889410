import { Injectable } from "@angular/core";

@Injectable({providedIn: 'root'})
export class OnlyAcceptNumbers {

  // Function responsible for making the input accept 
  // only numbers with a maximum of three numbers
  formatNumber(event: any) {

    const input = event.target;
    let value = input.value;
    const valueFormat = value.replace(/[^0-9]/g, '');

     input.value = valueFormat;

     return Number(valueFormat);
  }

  // Function responsible for formatting the price for the input and database
  formatPriceForInputAndDatabase(event: any) {

    const input = event.target;
    let priceTreatment = input.value;
    
    priceTreatment = priceTreatment.replace(/\D/g, ""); 
    priceTreatment = priceTreatment.replace(/(\d+)(\d{2})$/, "$1,$2"); 
	  priceTreatment = priceTreatment.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
	
    input.value = priceTreatment;

    let valueWithoutPoint = priceTreatment.replace(/\./g, '').replace(',', '.');
    
    return valueWithoutPoint; 
  }
  
  // Function responsible for formatting the price that 
  // will be displayed on the page according to the currency
  formattingFrontPrice(price: number, currency: string) {

    const numeroComDuasCasas = Number(price);
    let formatter: any;

    if (currency === 'R$') {
      
      formatter = new Intl.NumberFormat('pt-BR', {minimumFractionDigits: 2})
    } else {
      
      formatter = new Intl.NumberFormat('en-US', {minimumFractionDigits: 2})
    }
    
    return formatter.format(numeroComDuasCasas); 
  }

}
