import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng';
import { ConstantsFordProtect } from 'src/app/models/constants/constants-ford-protect';
import { PriceName } from 'src/app/models/enum/price-name';
import { CoverageCategory } from 'src/app/models/product/coverage-category';
import { PriceDto } from 'src/app/models/product/price-dto';
import { ProductPolicyEditDto } from 'src/app/models/product/product-policy-edit';
import { ProductPolicyRequestDto } from 'src/app/models/product/product-policy-request';
import { ProductPolicyResponseDto } from 'src/app/models/product/product-policy-response';
import { ProductVisit } from 'src/app/models/product/product-visit';
import { VinCategory } from 'src/app/models/vin/vin-category';
import { ApsPermissionService } from 'src/app/services/aps-permission/aps-permission.service';
import { CoverageService } from 'src/app/services/coverage/coverage.service';
import { PriceService } from 'src/app/services/price/price.service';
import { ProductPolicyService } from 'src/app/services/product-policy/product-policy.service';
import { ProductService } from 'src/app/services/product/product.service';
import { VinService } from 'src/app/services/vin/vin.service';
import { CountryName } from 'src/assets/functions/get_country_name';
import { OnlyAcceptNumbers } from 'src/assets/functions/only_accept_numbers';

@Component({
  selector: 'eligibility-details-edit',
  templateUrl: './eligibility-details-edit.component.html',
  styleUrls: ['./eligibility-details-edit.component.scss']
})
export class EligibilityDetailsEditComponent implements OnInit {

  listStatus = [
    { label: 'TRANSLATE.SELECT' },
    { label: 'TRANSLATE.ACTIVE', value: ConstantsFordProtect.COVERAGE_ACTIVE },
    { label: 'TRANSLATE.INACTIVE', value: ConstantsFordProtect.COVERAGE_INACTIVE }
  ];
  
  listYearVehicle = [
    { label: 'TRANSLATE.SELECT' },
    { label: 2021, value: 2021 },
    { label: 2022, value: 2022 },
    { label: 2023, value: 2023 },
    { label: 2024, value: 2024 },
    { label: 2025, value: 2025 },
    { label: 2026, value: 2026 },
    { label: 2027, value: 2027 },
    { label: 2028, value: 2028 },
    { label: 2029, value: 2029 }
  ];

  listSalesChannel = [
    { label: 'TRANSLATE.SELECT' },
    { label: 'TRANSLATE.DEALER', value: ConstantsFordProtect.DEALER },
    { label: 'TRANSLATE.TELEMARKETING', value: ConstantsFordProtect.TELEMARKETING }
  ];

  productPolicy: ProductPolicyEditDto = {} as ProductPolicyEditDto;
  id: number;

  selectedProducts: Array<any> = [];
  products: Array<any> = [];
  idProduct: number;
  placeholderProduct: string = 'TRANSLATE.SELECT';

  selectedCountry: string;
  country: string;

  selectedCoverages: Array<any> = [];
  coverages: Array<any> = [];
  coveragesConcat: Array<any> = [];
  idCoverage: number;
  placeholderCoverage: string = 'TRANSLATE.SELECT';

  selectCanalVenda: string;
  selectedStatus: number;
  selectedYearVehicle: number;
  year: number;

  selectedVehicleLine: string;
  vehicleLines: Array<any> = [];
  vehicleLineConcat: Array<any> = [];
  idVehicle: number;
  placeholderVehicle: string;

  selectedCustomerPrice: number;
  customersPrices: Array<any> = [];
  customersPricesConcat: Array<any> = [];
  placeholderPrice: string;
  priceName: string = PriceName.PRICE_CUSTOMER;
  idPriceName: number;
  productPolicyRequestDto: ProductPolicyRequestDto;
  productPolicyResponseDto: ProductPolicyResponseDto;
  editedProductPolicy: ProductPolicyEditDto = {} as ProductPolicyEditDto;
  productArray: Array<any> = [];
  selectDisabled: boolean;

  coverageCategoryId: number;
  selectedYear: number;
  selectedSalesChannel: number;
  inputMonthsOffer: number;
  blockedUi: boolean = false;
  countryNameResult: string;

  constructor(
    private priceService: PriceService,
    private translateService: TranslateService,
    private messageService: MessageService,
    private router: Router,
    private productService: ProductService,
    private coverageService: CoverageService,
    private productPolicyService: ProductPolicyService,
    private apsPermissionService: ApsPermissionService,
    private route: ActivatedRoute,
    private vinService: VinService,
    private countryName: CountryName,
    private onlyAcceptNumbers: OnlyAcceptNumbers

  ) { }

  ngOnInit() {

    this.route.paramMap.subscribe(params => {
      this.id = +params.get('id');
    });
    this.buttonCancel();
  }

  buttonReturn() {
    this.router.navigate(['view-plans'], { skipLocationChange: false, replaceUrl: false });
  }

  buttonEdit() {

    this.selectDisabled = false;
    (<HTMLInputElement>document.getElementById("monthsOffer")).disabled = false;
    (<HTMLInputElement>document.getElementById("titleDetails")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("titleEdit")).style.display = 'block';
    (<HTMLInputElement>document.getElementById("subTitle")).style.display = 'block';
    (<HTMLInputElement>document.getElementById("btnReturn")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("btnEdit")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("btnCancel")).style.display = 'block';
    (<HTMLInputElement>document.getElementById("btnSave")).style.display = 'block';
    this.getByCountryAndYear(this.productPolicy.country, this.productPolicy.modelYear);
  }

  buttonCancel() {

    this.getProductpolicyById();
    this.selectDisabled = true;
    (<HTMLInputElement>document.getElementById("status")).style.color = '#202020';
    (<HTMLInputElement>document.getElementById("status")).style.backgroundColor = '#E5E5E5';
    (<HTMLInputElement>document.getElementById("productIdName")).style.color = '#202020';
    (<HTMLInputElement>document.getElementById("productIdName")).style.backgroundColor = '#E5E5E5';
    (<HTMLInputElement>document.getElementById("coverageName")).style.color = '#202020';
    (<HTMLInputElement>document.getElementById("coverageName")).style.backgroundColor = '#E5E5E5';
    (<HTMLInputElement>document.getElementById("customerPrice")).style.color = '#202020';
    (<HTMLInputElement>document.getElementById("customerPrice")).style.backgroundColor = '#E5E5E5';
    (<HTMLInputElement>document.getElementById("vehicle")).style.color = '#202020';
    (<HTMLInputElement>document.getElementById("vehicle")).style.backgroundColor = '#E5E5E5';
    (<HTMLInputElement>document.getElementById("salesChannel")).style.color = '#202020';
    (<HTMLInputElement>document.getElementById("salesChannel")).style.backgroundColor = '#E5E5E5';
    (<HTMLInputElement>document.getElementById("yearVehicle")).style.color = '#202020';
    (<HTMLInputElement>document.getElementById("yearVehicle")).style.backgroundColor = '#E5E5E5';
    (<HTMLInputElement>document.getElementById("titleDetails")).style.display = 'block';
    (<HTMLInputElement>document.getElementById("titleEdit")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("subTitle")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("btnReturn")).style.display = 'block';
    (<HTMLInputElement>document.getElementById("btnEdit")).style.display = 'block';
    (<HTMLInputElement>document.getElementById("btnCancel")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("btnSave")).style.display = 'none';

    (<HTMLInputElement>document.getElementById("monthsOffer")).disabled = true;

    this.restoringValues();
  }

  statusChange(event: any) {
    this.selectedStatus = event.value;
  }

  getByCustomersPrices(policyId: number) {

    this.priceService.getByCustomersPrices(this.priceName)
      .subscribe((response: Array<PriceDto>) => {

        this.customersPrices = response;

        this.customersPricesConcat = this.customersPrices.map(object => {

          let formattedPrice;

          if (object.itemPriceUnit === 'R$') {
            formattedPrice = object.itemPrice.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

          } else if (object.itemPriceUnit === '$') {
            formattedPrice = object.itemPrice.toLocaleString('en-US', { style: 'currency', currency: 'USD' });

          }
          if (object.id === policyId) {
            this.placeholderPrice = `${formattedPrice}`;
            this.idPriceName = object.id;
          }
          return {
            label: `${formattedPrice}`,
            id: object.id
          }
        });
      }, (err) => {

        this.blockedUi = false;

        if (err.status == 400) {
          this.blockedUi = false;
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('TRANSLATE.ERROR'),
            detail: this.translateService.instant('TRANSLATE.PRICE.NOT.FOUND')
          });
        } else if (err.status === 401) {

          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('TRANSLATE.ERROR'),
            detail: this.translateService.instant('TRANSLATE.USER.WITHOUT.PERMISSION.REFRESH.PAGE')
          });

        } else {

          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('TRANSLATE.ERROR'),
            detail: this.translateService.instant('TRANSLATE.ERROR.CONTACT.SYSTEM.ADMINISTRATOR')
          });
        }

      });
  }

  customerPriceChange(event: any) {
    this.selectedCustomerPrice = event.value;
    const idPrices = JSON.stringify(this.selectedCustomerPrice);
    this.idPriceName = JSON.parse(idPrices).id;
  }

  yearVehicleChange(event: any) {
    this.selectedYearVehicle = event.value;
    this.year = this.selectedYearVehicle;
    this.getByCountryAndYear(this.productPolicy.country, this.year)
  }

  vehicleLineChange(event: any) {
    this.selectedVehicleLine = event.value;
    const id = JSON.stringify(this.selectedVehicleLine);
    this.idVehicle = JSON.parse(id).id;
  }

  canalVendasChange(event: any) {
    this.selectCanalVenda = event.value;
  }

  monthsOffer(monthsOffer: any) {

    this.inputMonthsOffer = this.onlyAcceptNumbers
      .formatNumber(monthsOffer);
  }

  getByCountryAndYear(country: string, year: number) {

    this.vinService
      .getByCountryAndYear(country, year)
      .subscribe((response: Array<VinCategory>) => {

        this.vehicleLines = response;

        this.vehicleLineConcat = this.vehicleLines.map(object => {
          if (object.idVinCategoryVersion === this.productPolicy.vinCategoryVersionId) {
            this.idVehicle = object.idVinCategoryVersion;
            this.placeholderVehicle = `${object.vehicleLine} - ${object.vehicleLineCode} - ${object.tmaCode}${object.versionCode !== null ? ' - ' + object.versionCode + '.' : '.'}`;
          }
          return {
            label: `${object.vehicleLine} - ${object.vehicleLineCode} - ${object.tmaCode}${object.versionCode !== null ? ' - ' + object.versionCode + '.' : '.'}`,
            id: object.idVinCategoryVersion
          }
        });
      }, (err) => {

        this.blockedUi = false;

        if (err.status == 400) {
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('TRANSLATE.ERROR'),
            detail: this.translateService.instant('TRANSLATE.COVERAGE.NOT.FOUND')
          });
        } else if (err.status === 401) {

          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('TRANSLATE.ERROR'),
            detail: this.translateService.instant('TRANSLATE.USER.WITHOUT.PERMISSION.REFRESH.PAGE')
          });

        } else {

          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('TRANSLATE.ERROR'),
            detail: this.translateService.instant('TRANSLATE.ERROR.CONTACT.SYSTEM.ADMINISTRATOR')
          });
        }

      });
  }

  getProducts(country: string, productId: number) {

    this.productService
      .getProductsByCountryForProductPolicies(country)
      .subscribe((response: Array<ProductVisit>) => {

        this.products = response;

        this.productArray = this.products.map(object => {
          if (object.productId === productId) {
            this.placeholderProduct = object.productName;
            this.idProduct = object.productId;
          }
          return {
            id: object.productId,
            label: object.productName
          }
        });

      }, (err) => {

        if (err.status == 400) {
          this.blockedUi = false;
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('TRANSLATE.ERROR'),
            detail: this.translateService.instant('TRANSLATE.PRODUCTS.NOT.FOUND')
          });
        } else if (err.status === 401) {

          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('TRANSLATE.ERROR'),
            detail: this.translateService.instant('TRANSLATE.USER.WITHOUT.PERMISSION.REFRESH.PAGE')
          });

        } else {

          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('TRANSLATE.ERROR'),
            detail: this.translateService.instant('TRANSLATE.ERROR.CONTACT.SYSTEM.ADMINISTRATOR')
          });
        }

      });

  }

  addSelectOption() {
    const selectOption = { id: null, label: this.translateService.instant('TRANSLATE.SELECT') };
    if (!this.productArray.some((option) => option.label === this.translateService.instant('TRANSLATE.SELECT'))) {
      this.productArray = [selectOption, this.productArray];
    }
  }

  coverageChange(event: any) {
    this.selectedCoverages = event.value;
    const id = JSON.stringify(this.selectedCoverages);
    this.idCoverage = JSON.parse(id).id;
  }

  productChange(event: any) {
    this.selectedProducts = event.value;
    const id = JSON.stringify(this.selectedProducts);
    this.idProduct = JSON.parse(id).id;
    this.getSearchByProductId(this.idProduct, this.coverageCategoryId);
  }

  getSearchByProductId(idProduct: number, coverageCategoryId: number) {

    this.coverageService
      .getSearchByProductId(idProduct)
      .subscribe((response: Array<CoverageCategory>) => {

        this.coverages = response;

        this.coveragesConcat = this.coverages.map(object => {
          if (object.id === coverageCategoryId) {
            this.placeholderCoverage = `${object.visit} Visitas - ${object.term} Meses ${object.distance !== null ? ' - ' + object.distance + ' Km.' : '.'}`;
            this.idCoverage = object.id;
          }
          return {
            label: `${object.visit} Visitas - ${object.term} Meses ${object.distance !== null ? ' - ' + object.distance + ' Km.' : '.'}`,
            id: object.id
          }
        });
        this.blockedUi = false;
      }, (err) => {

        if (err.status == 400) {
          this.blockedUi = false;
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('TRANSLATE.ERROR'),
            detail: this.translateService.instant('TRANSLATE.COVERAGE.NOT.FOUND')
          });
        }else if (err.status === 401) {

          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('TRANSLATE.ERROR'),
            detail: this.translateService.instant('TRANSLATE.USER.WITHOUT.PERMISSION.REFRESH.PAGE')
          });

        } else {

          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('TRANSLATE.ERROR'),
            detail: this.translateService.instant('TRANSLATE.ERROR.CONTACT.SYSTEM.ADMINISTRATOR')
          });
        }

      });

  }

  getProductpolicyById() {

    this.blockedUi = true;

    this.productPolicyService.getById(this.id)
      .subscribe(response => {

        this.getProducts(response.country, response.productId);
        this.getByCustomersPrices(response.itemPriceId);
        this.getSearchByProductId(response.productId, response.coverageCategoryId);
        this.coverageCategoryId = response.coverageCategoryId;
        this.productPolicy = response;
        this.selectedYear = this.productPolicy.modelYear;
        this.placeholderVehicle = `${response.vehicleLine} - ${response.vehicleLineCode} - ${response.tmaCode}${response.versionCode !== null ? ' - ' + response.versionCode + '.' : '.'}`;
        this.idVehicle = response.vinCategoryVersionId;
        this.selectCanalVenda = response.saleType.toString();
        this.selectedSalesChannel = response.saleType;
        this.countryNameResult = this.countryName.getCountryName(response.country);

        if (response.itemPriceId == null) {
          this.placeholderPrice = "TRANSLATE.SELECT";
        }
        this.selectedCustomerPrice = response.itemPriceId;
        if (response.isActive == 1) {
          this.selectedStatus = ConstantsFordProtect.COVERAGE_ACTIVE.valueOf();
        }
        else if (response.isActive == 0) {
          this.selectedStatus = ConstantsFordProtect.COVERAGE_INACTIVE.valueOf();
        }
      }, (err) => {

        this.blockedUi = false;
        (<HTMLButtonElement>document.getElementById('btnEdit')).disabled = true;

        if (err === 400) {

          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('TRANSLATE.ERROR'),
            detail: this.translateService.instant('TRANSLATE.ELEGIBILITY.NOT.FOUND')
          });
        } else if (err.status === 401) {

          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('TRANSLATE.ERROR'),
            detail: this.translateService.instant('TRANSLATE.USER.WITHOUT.PERMISSION.REFRESH.PAGE')
          });

        } else {

          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('TRANSLATE.ERROR'),
            detail: this.translateService.instant('TRANSLATE.ERROR.CONTACT.SYSTEM.ADMINISTRATOR')
          });
        }

      });
  }

  buttonSave() {

    if (this.inputMonthsOffer === undefined) {

      this.inputMonthsOffer = parseInt((<HTMLInputElement>document.getElementById("monthsOffer")).value);
    }
    if (this.inputMonthsOffer <= 0 || isNaN(this.inputMonthsOffer)) {
      
      this.inputMonthsOffer = null;
    }
    if (this.selectedStatus === undefined) {

      this.selectedStatus = null;      
    }
    if (this.selectedCustomerPrice === undefined) {

      this.selectedCustomerPrice = null;      
    }
    if (this.selectedCoverages === undefined) {

      this.selectedCoverages = null;      
    }
    if (this.selectedProducts === undefined) {

      this.selectedProducts = null;      
    }
    if (this.selectedVehicleLine === undefined && this.placeholderVehicle === null) {

      this.selectedVehicleLine = null;      
    }
    if (this.selectedSalesChannel === undefined) {

      this.selectedSalesChannel = null;      
    }
    if (this.selectedYear === undefined) {
      
      this.selectedYear = null;      
    }
    else {

      this.editedProductPolicy.productPolicyId = this.id;
      this.editedProductPolicy.coverageCategoryId = this.idCoverage;
      this.editedProductPolicy.productId = this.idProduct
      this.editedProductPolicy.vinCategoryVersionId = this.idVehicle;
      this.editedProductPolicy.country = this.productPolicy.country;
      this.editedProductPolicy.user = sessionStorage.getItem('userId');
      this.editedProductPolicy.saleType = parseInt(this.selectCanalVenda, 10);
      this.editedProductPolicy.itemPriceId = this.idPriceName;
      this.editedProductPolicy.months = this.inputMonthsOffer;
      this.editedProductPolicy.isActive = this.selectedStatus;
      this.editedProductPolicy.modelYear = this.selectedYear;

      this.productPolicyService.updateProductPolicy(this.editedProductPolicy)
        .subscribe(response => {

          this.messageService.add({
            severity: 'success',
            summary: this.translateService.instant('TRANSLATE.SUCCESS'),
            detail: this.translateService.instant('TRANSLATE.SUCCESS.EDIT')
          });
          this.getProductpolicyById();
          this.buttonCancel();

        }, (err) => {

          this.blockedUi = false;

          if (err.status === 400) {

            this.messageService.add({
              severity: 'error',
              summary: this.translateService.instant('TRANSLATE.ERROR'),
              detail: this.translateService.instant('TRANSLATE.ERROR.PLEASE.REQUIRED.FIELDS')
            });

          } else if (err.status === 404) {

            this.messageService.add({
              severity: 'error',
              summary: this.translateService.instant('TRANSLATE.ERROR'),
              detail: this.translateService.instant('TRANSLATE.ERROR.SAVE.CHANGES')
            });

          } else if (err.status === 401) {

            this.messageService.add({
              severity: 'error',
              summary: this.translateService.instant('TRANSLATE.ERROR'),
              detail: this.translateService.instant('TRANSLATE.USER.WITHOUT.PERMISSION.REFRESH.PAGE')
            });

          } else {

            this.messageService.add({
              severity: 'error',
              summary: this.translateService.instant('TRANSLATE.ERROR'),
              detail: this.translateService.instant('TRANSLATE.ERROR.CONTACT.SYSTEM.ADMINISTRATOR')
            });
          }

        });
    }

  }

  restoringValues() {
    this.selectedStatus = undefined;
    this.selectedSalesChannel = undefined;
    this.selectedCountry = undefined;
    this.selectedStatus = undefined;
    this.selectedVehicleLine = undefined;
    this.inputMonthsOffer= undefined;
  }

}
