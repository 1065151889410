export enum FileType {

  PURCHASE_TERM = 1,
  PURCHASE_TERM_DESCRIPTION= "Ford Protect Termo de Contratação.pdf",
	LICENSE_TERM = 2,
  LICENSE_TERM_DESCRIPTION= "Ford Protect Termo de Autorização de Cobrança.pdf",
	TICKET = 3,
  TICKET_DESCRIPTION= "Ford Protect Bilhete de Seguro.pdf",
	UPDATE_TICKET = 4,
  UPDATE_TICKET_DESCRIPTION= "Ford Protect Bilhete de Seguro Atualizado.pdf",
	CANCELLATION_TICKET = 5,
  CANCELLATION_TICKET_DESCRIPTION= "Cancelamento Bilhete AIG.pdf",
	CONTRACT_SUCCESS_CO = 6,
  CONTRACT_SUCCESS_CO_DESCRIPTION= "Ford Protect Termo Contratação Colombia.pdf",
	CONTRACT_SUCCESS_CH = 7,
  CONTRACT_SUCCESS_CH_DESCRIPTION= "Ford Protect Termo Contratação Chile.pdf",
	PENDING_SIGNATURE_CO = 8,
  PENDING_SIGNATURE_CO_DESCRIPTION= "Ford Protect Pendente de Assinatura - Colombia",
	PENDING_SIGNATURE_CH = 9,
  PENDING_SIGNATURE_CH_DESCRIPTION= "Ford Protect Pendente de Assinatura - Chile",
	INVALID = 99,
  INVALID_DESCRIPTION= "Tipo Inválido de Arquivo"
	
}