import { FileType } from "src/app/models/enum/file-type";
import { SalesTypeFordEnum } from "src/app/models/enum/sales-type-ford";

export function listStatusType() {

  const listStatusType = [];
  listStatusType.push(SalesTypeFordEnum.FORD_FLEET_CODE);
  listStatusType.push(SalesTypeFordEnum.FORD_GO_CODE);

  return listStatusType;
}

export function fileTypeTerm() {

  const fileTypeTerm = [];
  fileTypeTerm.push(FileType.PURCHASE_TERM);
  fileTypeTerm.push(FileType.LICENSE_TERM);
  fileTypeTerm.push(FileType.CONTRACT_SUCCESS_CO);
  fileTypeTerm.push(FileType.CONTRACT_SUCCESS_CH);

  return fileTypeTerm;
}

export function fileTypeTicket() {

  const fileTypeTicket = [];
  fileTypeTicket.push(FileType.TICKET);
  fileTypeTicket.push(FileType.UPDATE_TICKET);
  fileTypeTicket.push(FileType.CANCELLATION_TICKET);
  
  return fileTypeTicket;
}