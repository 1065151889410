import { Injectable } from "@angular/core";

@Injectable({providedIn: 'root'})
export class FormatOdometerNumber {
  
  odometerFormatting(distance: number) {

    let odometereTreatment = distance.toString();

    odometereTreatment = odometereTreatment.replace(/\D/g, "");
    odometereTreatment = odometereTreatment.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");

    return odometereTreatment;

  }
 
}