import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng';
import { ConstantsFordProtect } from 'src/app/models/constants/constants-ford-protect';
import { PriceEdit } from 'src/app/models/product/price-edit';
import { ApsPermissionService } from 'src/app/services/aps-permission/aps-permission.service';
import { PriceService } from 'src/app/services/price/price.service';
import { OnlyAcceptNumbers } from 'src/assets/functions/only_accept_numbers';

@Component({
  selector: 'price-details-edit',
  templateUrl: './price-details-edit.component.html',
  styleUrls: ['./price-details-edit.component.scss']
})
export class PriceDetailsEditComponent implements OnInit {

  listNamePrice = [
    { label: 'TRANSLATE.SELECT' },
    { label: 'TRANSLATE.SELECT.PRICE.CUSTOMER', value: ConstantsFordProtect.PRICE_CUSTOMER },
    { label: 'TRANSLATE.SELECT.SELLER.PRICE', value: ConstantsFordProtect.SELLER_PRICE },
    { label: 'TRANSLATE.SELECT.COST.FORD', value: ConstantsFordProtect.COST_FORD }
  ];

  listCurrency = [
    { label: 'TRANSLATE.SELECT' },
    { label: 'TRANSLATE.SELECT.COUNTRY.CURRENCY.BRA', value: ConstantsFordProtect.REAL },
    { label: 'TRANSLATE.SELECT.COUNTRY.CURRENCY.OTHERS', value: ConstantsFordProtect.PESO }
  ];

  selectedNamePrice: string;
  selectedCurrency: string;
  inputPrice: number;
  inputDescription: string;
  selectDisabled: boolean;
  id: number;
  price: PriceEdit = {} as PriceEdit;
  editedPrice: PriceEdit = {} as PriceEdit;
  blockedUi: boolean = false;
  frontPrice: string;
  finalPrice: number;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private priceService: PriceService,
    private apsPermissionService: ApsPermissionService,
    private translateService: TranslateService,
    private messageService: MessageService,
    private onlyAcceptNumbers: OnlyAcceptNumbers

  ) { }

  ngOnInit() {

    this.route.paramMap.subscribe(params => {
      this.id = +params.get('id');
    });
    this.buttonCancel();
  }

  buttonReturn() {
    this.router.navigate(['view-plans'], { skipLocationChange: false, replaceUrl: false });
  }

  namePriceChange(event: any) {
    this.selectedNamePrice = event.value;
  }

  currencyChange(event: any) {
    this.selectedCurrency = event.value;
  }

  priceFormatting(price: any) {

    let valor = this.onlyAcceptNumbers.formatPriceForInputAndDatabase(price);
    this.inputPrice = valor;
  }

  buttonEdit() {

    (<HTMLInputElement>document.getElementById("valuePrice")).disabled = false;
    (<HTMLInputElement>document.getElementById("priceDescription")).disabled = false;
    this.selectDisabled = false;

    (<HTMLInputElement>document.getElementById("titleDetails")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("titleEdit")).style.display = 'block';
    (<HTMLInputElement>document.getElementById("subTitle")).style.display = 'block';
    (<HTMLInputElement>document.getElementById("btnReturn")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("btnEdit")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("btnCancel")).style.display = 'block';
    (<HTMLInputElement>document.getElementById("btnSave")).style.display = 'block';

  }

  buttonCancel() {

    (<HTMLInputElement>document.getElementById("valuePrice")).disabled = true;
    (<HTMLInputElement>document.getElementById("priceDescription")).disabled = true;
    (<HTMLInputElement>document.getElementById("currency")).style.color = '#202020';
    (<HTMLInputElement>document.getElementById("currency")).style.backgroundColor = '#E5E5E5';
    (<HTMLInputElement>document.getElementById("namePrice")).style.color = '#202020';
    (<HTMLInputElement>document.getElementById("namePrice")).style.backgroundColor = '#E5E5E5';
    this.selectDisabled = true;
    (<HTMLInputElement>document.getElementById("titleDetails")).style.display = 'block';
    (<HTMLInputElement>document.getElementById("titleEdit")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("subTitle")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("btnReturn")).style.display = 'block';
    (<HTMLInputElement>document.getElementById("btnEdit")).style.display = 'block';
    (<HTMLInputElement>document.getElementById("btnCancel")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("btnSave")).style.display = 'none';

    this.restoringValues();
  }

  buttonSave() {

    this.blockedUi = true;

    this.inputDescription = (<HTMLInputElement>document.getElementById("priceDescription")).value;

    if (this.selectedNamePrice === undefined) {

      this.selectedNamePrice = null;
      this.blockedUi = false;
    }
    if (this.selectedCurrency === undefined) {

      this.selectedCurrency = null;
      this.blockedUi = false;
    }
    if (isNaN(this.inputPrice)) {

      this.inputPrice = this.price.itemPrice;
    }
    if (this.inputPrice <= 0) {

      this.inputPrice = null;
      this.blockedUi = false;
    }
    if (this.inputDescription.length < 4) {

      this.inputDescription = null;
      this.blockedUi = false;

    } else {

      this.editedPrice.itemPrice = this.inputPrice;
      this.editedPrice.itemName = this.selectedNamePrice;
      this.editedPrice.itemPriceUnit = this.selectedCurrency;
      this.editedPrice.itemDescription = this.inputDescription;
      this.editedPrice.createUser = this.apsPermissionService.getUserCsid();
      this.updatePrice();
    }

  }

  restoringValues() {

    this.selectedNamePrice = '';
    this.selectedCurrency = '';
    this.searchById();
  }

  searchById() {

    this.blockedUi = true;
    this.priceService.getByPriceId(this.id).subscribe((response) => {
      this.price.priceId = response.id;
      this.price.itemName = response.itemName;
      this.price.itemDescription = response.itemDescription;
      this.price.itemPrice = response.itemPrice;

      this.frontPrice = this.onlyAcceptNumbers
      .formattingFrontPrice(response.itemPrice, response.itemPriceUnit);

      this.price.itemPriceUnit = response.itemPriceUnit;
      this.selectedCurrency = response.itemPriceUnit;
      this.selectedNamePrice = response.itemName;
      this.blockedUi = false;
    }, (err) => {

      this.blockedUi = false;

      if (err === 400) {
        
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('TRANSLATE.ERROR'),
          detail: this.translateService.instant('TRANSLATE.PRICE.NOT.FOUND')
        });
      } else if (err.status === 401) {

        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('TRANSLATE.ERROR'),
          detail: this.translateService.instant('TRANSLATE.USER.WITHOUT.PERMISSION.REFRESH.PAGE')
        });

      } else {

        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('TRANSLATE.ERROR'),
          detail: this.translateService.instant('TRANSLATE.ERROR.CONTACT.SYSTEM.ADMINISTRATOR')
        });
      }

    });

  }

  updatePrice() {

    this.priceService.updatePrice(this.editedPrice, this.price.priceId)
      .subscribe(response => {

        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('TRANSLATE.SUCCESS'),
          detail: this.translateService.instant('TRANSLATE.SUCCESS.EDIT')
        });

        this.blockedUi = false;
        this.buttonCancel();
        this.searchById();

      }, (err) => {

        this.blockedUi = false;

        if (err.status === 400) {

          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('TRANSLATE.ERROR'),
            detail: this.translateService.instant('TRANSLATE.ERROR.PLEASE.REQUIRED.FIELDS')
          });

        } else if (err.status === 404) {

          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('TRANSLATE.ERROR'),
            detail: this.translateService.instant('TRANSLATE.ERROR.SAVE.CHANGES')
          });

        } else if (err.status === 401) {

          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('TRANSLATE.ERROR'),
            detail: this.translateService.instant('TRANSLATE.USER.WITHOUT.PERMISSION.REFRESH.PAGE')
          });

        } else {

          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('TRANSLATE.ERROR'),
            detail: this.translateService.instant('TRANSLATE.ERROR.CONTACT.SYSTEM.ADMINISTRATOR')
          });
        }
      })
  }

}
