import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng';
import { ApsPermitedScreen } from 'src/app/models/aps-permission/aps-permited-screen';
import { ConstantsFordProtect } from 'src/app/models/constants/constants-ford-protect';
import { OperationCode } from 'src/app/models/enum/operation-code';
import { StatusProduct } from 'src/app/models/enum/status-product';
import { CoverageCategory } from 'src/app/models/product/coverage-category';
import { NewCoverageCategory } from 'src/app/models/product/new-coverage-category';
import { ProductActive } from 'src/app/models/product/product-active';
import { ProductEdit } from 'src/app/models/product/product-edit';
import { ApsPermissionService } from 'src/app/services/aps-permission/aps-permission.service';
import { CoverageService } from 'src/app/services/coverage/coverage.service';
import { ProductService } from 'src/app/services/product/product.service';

@Component({
  selector: 'new-coverage',
  templateUrl: './new-coverage.component.html',
  styleUrls: ['./new-coverage.component.scss']
})
export class NewCoverageComponent implements OnInit {

  listCountries = [
    { label: 'TRANSLATE.SELECT' },
    { label: 'TRANSLATE.ARGENTINA', value: ConstantsFordProtect.ARG },
    { label: 'TRANSLATE.BRAZIL', value: ConstantsFordProtect.BRA },
    { label: 'TRANSLATE.CHILE', value: ConstantsFordProtect.CHL },
    { label: 'TRANSLATE.COLOMBIA', value: ConstantsFordProtect.COL }
  ];

  listTypeCoverage = [
    { label: 'TRANSLATE.SELECT' },
    { label: 'TRANSLATE.UPPERCASE.MAINTENANCE', value: ConstantsFordProtect.MAINTENANCE },
    { label: 'TRANSLATE.UPPERCASE.EXTENSION', value: ConstantsFordProtect.EXTENSION },
    { label: 'TRANSLATE.UPPERCASE.REPAIRS', value: ConstantsFordProtect.REPAIRS },
    { label: 'TRANSLATE.UPPERCASE.SIGNALS', value: ConstantsFordProtect.SIGNALS },
    { label: 'TRANSLATE.UPPERCASE.WINCHES', value: ConstantsFordProtect.WINCHES },
    { label: 'TRANSLATE.UPPERCASE.WEAR', value: ConstantsFordProtect.WEAR }
  ];

  userform: FormGroup;
  coverageCategory: CoverageCategory;
  newCoverageCategory: NewCoverageCategory;
  selectedCountry: string;
  selectedProductName: any;
  selectedCoverageType: string;

  productActiveCountries: Array<ProductActive> = [];
  productActive: ProductActive;
  productPlanYear: string;
  productAccount: string;
  productActionCode: string;
  productCoverageCode: string;
  productInsuranceCode: string;
  placeholderProduct: string = 'TRANSLATE.SELECT';
  blockedUi: boolean = false;


  coverageCategorys: Array<CoverageCategory> = [];

  accountCodeUppercase: string;
  editableAccount: boolean = false;
  messageAccountCode: boolean = false;
  placeholderAccountCode: string;

  actionCodeUppercase: string;
  editableActionCodeOWS: boolean = false;
  messageActionCodeOWS: boolean = false;
  placeholderActionCodeOWS: string;

  coverageUppercase: string;
  editableCoverageCodeOWS: boolean = false;
  messageCoverageCodeOWS: boolean = false;
  placeholderCoverageCodeOWS: string;

  insuranceCodeUppercase: string;
  editableInsuranceCode: boolean = false;
  messageInsuranceCode: boolean = false;
  placeholderInsuranceCode: string;
  inputOdometer: number;

  operation: string = OperationCode.CAR;
  statusProduct: number = StatusProduct.ACTIVE;

  listAccountCodeOWS = [
		{ label: 'TRANSLATE.SELECT' },
		{ label: ConstantsFordProtect.ESB, value: ConstantsFordProtect.ESB },
		{ label: ConstantsFordProtect.ESBG, value: ConstantsFordProtect.ESBG },
		{ label: ConstantsFordProtect.ESMP, value: ConstantsFordProtect.ESMP },
		{ label: ConstantsFordProtect.EXTE, value: ConstantsFordProtect.EXTE },
		{ label: ConstantsFordProtect.FLEE, value: ConstantsFordProtect.FLEE },
		{ label: ConstantsFordProtect.FLSN, value: ConstantsFordProtect.FLSN },
		{ label: ConstantsFordProtect.FLMT, value: ConstantsFordProtect.FLMT },
		{ label: ConstantsFordProtect.NULO, value: null },
		{ label: ConstantsFordProtect.OTHERS, value: ConstantsFordProtect.OTHERS }
	];

	listActionCodeOWS = [
		{ label: 'TRANSLATE.SELECT' },
		{ label: ConstantsFordProtect.A, value: ConstantsFordProtect.A },
		{ label: ConstantsFordProtect.NULO, value: null },
		{ label: ConstantsFordProtect.OTHERS, value: ConstantsFordProtect.OTHERS }
	];

	listCoverageCodeOWS = [
		{ label: 'TRANSLATE.SELECT' },
		{ label: ConstantsFordProtect.AST, value: ConstantsFordProtect.AST },
		{ label: ConstantsFordProtect.ESP, value: ConstantsFordProtect.ESP },
		{ label: ConstantsFordProtect.FLMT, value: ConstantsFordProtect.FLMT },
		{ label: ConstantsFordProtect.FLSN, value: ConstantsFordProtect.FLSN },
		{ label: ConstantsFordProtect.QCM, value: ConstantsFordProtect.QCM },
		{ label: ConstantsFordProtect.NULO, value: null },
		{ label: ConstantsFordProtect.OTHERS, value: ConstantsFordProtect.OTHERS }
	];

	listInsuranceCode = [
		{ label: 'TRANSLATE.SELECT' },
		{ label: ConstantsFordProtect.AST, value: ConstantsFordProtect.AST },
		{ label: ConstantsFordProtect.MNTC, value: ConstantsFordProtect.MNTC },
		{ label: ConstantsFordProtect.QCM, value: ConstantsFordProtect.QCM },
		{ label: ConstantsFordProtect.REP, value: ConstantsFordProtect.REP },
		{ label: ConstantsFordProtect.WTY, value: ConstantsFordProtect.WTY },
		{ label: ConstantsFordProtect.NULO, value: null },
		{ label: ConstantsFordProtect.OTHERS, value: ConstantsFordProtect.OTHERS }
	];
  
  constructor(
    private fb: FormBuilder,
    private serviceProduct: ProductService,
    private messageService: MessageService,
    private apsPermissionService: ApsPermissionService,
    private translateService: TranslateService,
    private router: Router,
    private coverageService: CoverageService,
    private productService: ProductService
  ) {
    this.coverageCategory = new CoverageCategory();
    this.newCoverageCategory = new NewCoverageCategory();
  }

  

  ngOnInit() {

    this.apsPermissionService.permissionValidation(ApsPermitedScreen.screenViewsPlans);

    this.userform = this.fb.group({
      'optionCountry': [null, Validators.required],
      'optionProductName': [null, Validators.required],
      'optionCoverageType': [null, Validators.required],
      'inputCoverageName': ['', [Validators.required, Validators.minLength(4), Validators.maxLength(20)]],
      'inputCoverageDescription': ['', [Validators.required, Validators.minLength(4), Validators.maxLength(100)]],
      'inputOwsCoverage': ['', [Validators.required, Validators.minLength(4), Validators.maxLength(8)]],
      'inputMonths': ['', [Validators.required, Validators.min(1), Validators.max(99), Validators.maxLength(2), Validators.pattern('[0-9]*')]],
      'inputDistance': ['', [Validators.required, Validators.min(1), Validators.max(999999), Validators.maxLength(7), Validators.pattern('[0-9.]*')]],
      'inputTotalVisits': ['', [Validators.required, Validators.min(1), Validators.max(99), Validators.maxLength(2), Validators.pattern('[0-9]*')]],
      'optionAccount': [null, [Validators.maxLength(9)]],
      'optionActionCode': [null, [Validators.maxLength(1)]],
      'optionCoverageCode': [null, [Validators.maxLength(4)]],
      'optionInsuranceCode': [null, [Validators.maxLength(4)]],
      'inputInitialVisit': ['', [Validators.required, Validators.min(1), Validators.max(99), Validators.maxLength(2), Validators.pattern('[0-9]*')]]
    });

  }

  buttonReturn() {
    this.router.navigate(['view-plans'], { skipLocationChange: false, replaceUrl: false });
  }

  countryChange(event: any) {
    this.selectedCountry = event.value;

    this.searchProductActiveCountry(this.selectedCountry);
  }

  private searchProductActiveCountry(country: string) {

		this.serviceProduct.getActivesProductsByCountry(country, this.operation, this.statusProduct)
			.subscribe((response: Array<ProductActive>) => {
				this.productActiveCountries = response;

        if (this.productActiveCountries.length > 0) {
          this.placeholderProduct = 'TRANSLATE.SELECT';
        } else {
          this.placeholderProduct = 'TRANSLATE.NO.RESULT.FOUND';
        }

        if (this.productActiveCountries.length > 0) {
          this.productActive = this.productActiveCountries[0];
          this.getProductById(this.productActive.id);

        } else {
          this.productActive = new ProductActive();
        }

      }, (err) => {
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('TRANSLATE.ERROR'),
          detail: this.translateService.instant('TRANSLATE.PRODUCTS.NOT.FOUND')
        });
      });
  }

  formatNumber(event: any) {

    const input = event.target;
    let value = input.value;
    const valueFormat = value.replace(/[^0-9]/g, '');

    input.value = valueFormat;
    return valueFormat;
  }

  formatOdometerNumber(event: any) {

    const input = event.target as HTMLInputElement;
    let odometereTreatment = input.value;

    odometereTreatment = odometereTreatment.replace(/\D/g, "");
    odometereTreatment = odometereTreatment.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");

    input.value = odometereTreatment;

    let valueWithoutPoint = odometereTreatment.replace(/\./g, '').replace('.', ',');
    this.inputOdometer = parseInt(valueWithoutPoint);
  }

  productChange(event: any) {
    this.getProductById(event.value.id);
  }

  private getProductById(productId: number) {
    this.productService.getProductById(productId)
      .subscribe((response: ProductEdit) => {
        this.selectedProductName = productId;
        
          this.productPlanYear = response.planYear;
          this.productAccount = response.accountNumber;
          this.accountCodeUppercase = this.productAccount;

          this.listAccountCodeOWS = [
            { label: ConstantsFordProtect.ESB, value: ConstantsFordProtect.ESB },
            { label: ConstantsFordProtect.ESBG, value: ConstantsFordProtect.ESBG },
            { label: ConstantsFordProtect.ESMP, value: ConstantsFordProtect.ESMP },
            { label: ConstantsFordProtect.EXTE, value: ConstantsFordProtect.EXTE },
            { label: ConstantsFordProtect.FLEE, value: ConstantsFordProtect.FLEE },
            { label: ConstantsFordProtect.FLSN, value: ConstantsFordProtect.FLSN },
            { label: ConstantsFordProtect.FLMT, value: ConstantsFordProtect.FLMT },
            { label: ConstantsFordProtect.NULO, value: null },
            { label: ConstantsFordProtect.OTHERS, value: ConstantsFordProtect.OTHERS }
          ];

          this.productActionCode = response.actionCode;
          this.actionCodeUppercase = this.productActionCode;

          this.listActionCodeOWS = [
            { label: ConstantsFordProtect.A, value: ConstantsFordProtect.A },
            { label: ConstantsFordProtect.NULO, value: null },
            { label: ConstantsFordProtect.OTHERS, value: ConstantsFordProtect.OTHERS }
          ];

          this.productCoverageCode = response.coverageCode;
          this.coverageUppercase = this.productCoverageCode;

          this.listCoverageCodeOWS = [
            { label: ConstantsFordProtect.AST, value: ConstantsFordProtect.AST },
            { label: ConstantsFordProtect.ESP, value: ConstantsFordProtect.ESP },
            { label: ConstantsFordProtect.FLMT, value: ConstantsFordProtect.FLMT },
            { label: ConstantsFordProtect.FLSN, value: ConstantsFordProtect.FLSN },
            { label: ConstantsFordProtect.QCM, value: ConstantsFordProtect.QCM },
            { label: ConstantsFordProtect.NULO, value: null },
            { label: ConstantsFordProtect.OTHERS, value: ConstantsFordProtect.OTHERS }
          ];

          this.productInsuranceCode = response.insuranceCode;
          this.insuranceCodeUppercase = this.productInsuranceCode;

          this.listInsuranceCode = [
            { label: ConstantsFordProtect.AST, value: ConstantsFordProtect.AST },
            { label: ConstantsFordProtect.MNTC, value: ConstantsFordProtect.MNTC },
            { label: ConstantsFordProtect.QCM, value: ConstantsFordProtect.QCM },
            { label: ConstantsFordProtect.REP, value: ConstantsFordProtect.REP },
            { label: ConstantsFordProtect.WTY, value: ConstantsFordProtect.WTY },
            { label: ConstantsFordProtect.NULO, value: null },
            { label: ConstantsFordProtect.OTHERS, value: ConstantsFordProtect.OTHERS }
          ];
          this.coverageCategory = new CoverageCategory();

      }, (err) => {
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('TRANSLATE.ERROR'),
          detail: this.translateService.instant('TRANSLATE.PRODUCTS.NOT.FOUND')
        });
      });
  }

  accountOnChange(event: any) {

    this.messageAccountCode = false;

    this.accountCodeUppercase = event.value;

    if (this.accountCodeUppercase === ConstantsFordProtect.OTHERS) {

      this.editableAccount = true;
      this.placeholderAccountCode = this.translateService.instant('TRANSLATE.INFORM.THE.OTHER');
    }
    else {

      this.accountCodeUppercase;
    }

  }

  actionCodeOnChange(event: any) {

    this.messageActionCodeOWS = false;

    this.actionCodeUppercase = event.value;

    if (this.actionCodeUppercase === ConstantsFordProtect.OTHERS) {

      this.editableActionCodeOWS = true;
      this.placeholderActionCodeOWS = this.translateService.instant('TRANSLATE.INFORM.THE.OTHER');
    }
    else {

      this.actionCodeUppercase;
    }

  }

  coverageCodeOnChange(event: any) {

    this.coverageUppercase = event.value;

    this.messageCoverageCodeOWS = false;

    if (this.coverageUppercase === ConstantsFordProtect.OTHERS) {

      this.editableCoverageCodeOWS = true;
      this.placeholderCoverageCodeOWS = this.translateService.instant('TRANSLATE.INFORM.THE.OTHER');
    }
    else {

      this.coverageUppercase;
    }

  }

  insuranceCodeOnChange(event: any) {

    this.insuranceCodeUppercase = event.value;

    this.messageInsuranceCode = false;

    if (this.insuranceCodeUppercase === ConstantsFordProtect.OTHERS) {

      this.editableInsuranceCode = true;
      this.placeholderInsuranceCode = this.translateService.instant('TRANSLATE.INFORM.THE.OTHER');
    }
    else {

      this.insuranceCodeUppercase;
    }

  }

  optionCoverageTypeChange(event: any) {
    this.selectedCoverageType = event.value;
  }

  buttonSave() {

    if (this.selectedCountry === undefined || this.selectedProductName === null) {
      this.userform.get('optionCountry').markAsTouched();
      this.selectedCountry = null;
    }
    if (this.selectedProductName === undefined || this.selectedProductName === null) {
      this.userform.get('optionProductName').markAsTouched();
      this.selectedProductName = null;
    }
    if (this.selectedCoverageType === undefined || this.selectedCoverageType === null) {
      this.userform.get('optionCoverageType').markAsTouched();
      this.selectedCoverageType = null;
    }
    if (this.userform.get('inputCoverageName').invalid) {
      this.userform.get('inputCoverageName').markAsPending();
    }
    if (this.userform.get('inputCoverageDescription').invalid) {
      this.userform.get('inputCoverageDescription').markAsPending();
    }
    if (this.userform.get('inputOwsCoverage').invalid) {
      this.userform.get('inputOwsCoverage').markAsPending();
    }
    if (this.userform.get('inputMonths').invalid) {
      this.userform.get('inputMonths').markAsPending();
    }
    if (this.userform.get('inputDistance').invalid) {
      this.userform.get('inputDistance').markAsPending();
    }
    if (this.userform.get('inputTotalVisits').invalid) {
      this.userform.get('inputTotalVisits').markAsPending();
    }
    if (this.userform.get('inputInitialVisit').invalid) {
      this.userform.get('inputInitialVisit').markAsPending();
    }
    if (this.userform.get('inputDistance').invalid) {
      this.userform.get('inputDistance').markAsPending();
    }
    if (this.userform.get('inputTotalVisits').invalid) {
      this.userform.get('inputTotalVisits').markAsPending();
    }
    if (this.accountCodeUppercase === ConstantsFordProtect.OTHERS) {
      
      this.messageAccountCode = true;
    }
    if (this.actionCodeUppercase === ConstantsFordProtect.OTHERS) {
      
      this.messageActionCodeOWS = true;
    }
    if (this.coverageUppercase === ConstantsFordProtect.OTHERS) {
      
      this.messageCoverageCodeOWS = true;
    }
    if (this.insuranceCodeUppercase === ConstantsFordProtect.OTHERS) {
      
      this.messageInsuranceCode = true;
    }
    else if (this.userform.valid) {

      this.newCoverageCategory.productId = this.productActive.id;
      this.newCoverageCategory.type = this.selectedCoverageType;
      this.newCoverageCategory.distanceUnit = ConstantsFordProtect.DISTANCE_UNIT;
      this.newCoverageCategory.termUnit = ConstantsFordProtect.TERM_UNIT;
      this.newCoverageCategory.createUser = this.apsPermissionService.getUserCsid();
      this.newCoverageCategory.owsCoverage = this.newCoverageCategory.owsCoverage.toUpperCase();
      this.newCoverageCategory.distance = this.inputOdometer;

      if (this.accountCodeUppercase !== undefined) {

        if (this.accountCodeUppercase !== null) {

          this.newCoverageCategory.accountNumber = this.accountCodeUppercase.toUpperCase();
        } else {

          this.newCoverageCategory.accountNumber = this.accountCodeUppercase;
        }

      }
      if (this.actionCodeUppercase !== undefined) {

        if (this.actionCodeUppercase !== null) {

          this.newCoverageCategory.actionCode = this.actionCodeUppercase.toUpperCase();
        }
        else {

          this.newCoverageCategory.actionCode = this.actionCodeUppercase;
        }

      }
      if (this.coverageUppercase !== undefined) {

        if (this.coverageUppercase !== null) {

          this.newCoverageCategory.coverageCode = this.coverageUppercase.toUpperCase();
        }
        else {

          this.newCoverageCategory.coverageCode = this.coverageUppercase;
        }

      }
      if (this.insuranceCodeUppercase !== undefined) {

        if (this.insuranceCodeUppercase !== null) {

          this.newCoverageCategory.insuranceCode = this.insuranceCodeUppercase.toUpperCase();
        }
        else {

          this.newCoverageCategory.insuranceCode = this.insuranceCodeUppercase;
        }

      }
      
      if (
        this.accountCodeUppercase !== undefined &&
        this.accountCodeUppercase !== ConstantsFordProtect.OTHERS &&
        this.actionCodeUppercase !== undefined &&
        this.actionCodeUppercase !== ConstantsFordProtect.OTHERS &&
        this.coverageUppercase !== undefined &&
        this.coverageUppercase !== ConstantsFordProtect.OTHERS &&
        this.insuranceCodeUppercase !== undefined &&
        this.insuranceCodeUppercase !== ConstantsFordProtect.OTHERS
      ) {
        this.blockedUi = true;
        this.coverageService.createCoverage(this.newCoverageCategory)
        .subscribe((response: NewCoverageCategory) => {
  
          this.newCoverageCategory = response;
          this.blockedUi = false;
          this.messageService.add({
            severity: 'success',
            summary: this.translateService.instant('TRANSLATE.SUCCESS'),
            detail: this.translateService.instant('TRANSLATE.SUCCESS.CREATE')
          });
          this.buttonReturn();
  
        }, (err) => {
          this.blockedUi = false;
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('TRANSLATE.ERROR'),
            detail: this.translateService.instant('TRANSLATE.ERROR.CREATE.COVERAGE')
          });
        });
          
      }     
      
    } else {

      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('TRANSLATE.ERROR'),
        detail: this.translateService.instant('TRANSLATE.ERROR.PLEASE.REQUIRED.FIELDS')
      });
    }

  }

}