import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Injectable({providedIn: 'root'})
export class CountryName {

  constructor ( private translateService: TranslateService ) {}
  
  getCountryName(code: string) {
    
    const countryMap: { [key: string]: string } = {
      'ARG': this.translateService.instant('TRANSLATE.ARGENTINA'),
      'BRA': this.translateService.instant('TRANSLATE.BRAZIL'),
      'CHL': this.translateService.instant('TRANSLATE.CHILE'),
      'COL': this.translateService.instant('TRANSLATE.COLOMBIA')
    }

    return countryMap[code] || code;
  }
 
}