export class TermGeneration {

  contractId: number;  
	fileId: number;

  constructor(contractId: number, fileId: number) {
    this.contractId = contractId;
    this.fileId = fileId;
	}
	
}