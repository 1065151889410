import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng';
import { ConstantsFordProtect } from 'src/app/models/constants/constants-ford-protect';
import { Product } from 'src/app/models/product/product';
import { ProductEdit } from 'src/app/models/product/product-edit';
import { ApsPermissionService } from 'src/app/services/aps-permission/aps-permission.service';
import { ProductService } from 'src/app/services/product/product.service';
import { CountryName } from 'src/assets/functions/get_country_name';
import { OnlyAcceptNumbers } from 'src/assets/functions/only_accept_numbers';

@Component({
  selector: 'product-details-edit',
  templateUrl: './product-details-edit.component.html',
  styleUrls: ['./product-details-edit.component.scss'],
  providers: [DatePipe] //DatePipe como provider

})
export class ProductDetailsEditComponent implements OnInit {

  inputProductName: string;
  inputProductDescription: string;
  inputProductStatus: string;
  inputProductVisit: number;
  inputProductCode: string;
  inputProductAccountCode: string;
  inputProductActionCode: string;
  inputProductCoverageCode: string;
  inputProductInsuranceCode: string;
  inputProductProductDetails: string;
  inputProductInvoiceMonths: number;

  actionCodeUppercase: string;
  accountCodeUppercase: string;
  coverageUppercase: string;
  insuranceCodeUppercase: string;
  id: number;
  product: ProductEdit = {} as ProductEdit;
  editedProduct: ProductEdit = {} as ProductEdit;
  blockedUi: boolean = false;
  selectedStatus: any;
  selectDisabled: boolean = true;
  productCreationDateformat: string;
  countryNameResult: string;
  editionEnabled: boolean = true;

  listStatus = [
    { label: 'TRANSLATE.SELECT' },
    { label: 'TRANSLATE.ACTIVE', value: ConstantsFordProtect.PRODUCT_ACTIVE },
    { label: 'TRANSLATE.INACTIVE', value: ConstantsFordProtect.PRODUCT_INACTIVE }
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private productService: ProductService,
    private apsPermissionService: ApsPermissionService,
    private messageService: MessageService,
    public translateService: TranslateService,
    private datePipe: DatePipe,
    private countryName: CountryName,
    private onlyAcceptNumbers: OnlyAcceptNumbers

  ) {

    this.route.paramMap.subscribe(params => {
      this.id = +params.get('id');
    });
  }

  ngOnInit() {

    this.blockedUi = true;
    this.searchProduct(this.id);
    this.buttonCancel();
    this.blockedUi = true;
  }

  buttonReturn() {
    this.router.navigate(['view-plans'], { skipLocationChange: false, replaceUrl: false });
  }

  buttonEdit() {

    this.selectDisabled = false;
    (<HTMLInputElement>document.getElementById("productName")).disabled = false;
    (<HTMLInputElement>document.getElementById("productStatus")).disabled = false;
    (<HTMLInputElement>document.getElementById("productCode")).disabled = false;
    (<HTMLInputElement>document.getElementById("visit")).disabled = false;
    (<HTMLInputElement>document.getElementById("productDescription")).disabled = false;
    (<HTMLInputElement>document.getElementById("account")).disabled = false;
    (<HTMLInputElement>document.getElementById("actionCode")).disabled = false;
    (<HTMLInputElement>document.getElementById("coverageCode")).disabled = false;
    (<HTMLInputElement>document.getElementById("insuranceCode")).disabled = false;
    (<HTMLInputElement>document.getElementById("productDetails")).disabled = false;
    (<HTMLInputElement>document.getElementById("invoiceMonths")).disabled = false;
    (<HTMLInputElement>document.getElementById("titleDetails")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("titleEdit")).style.display = 'block';
    (<HTMLInputElement>document.getElementById("subTitle")).style.display = 'block';
    (<HTMLInputElement>document.getElementById("btnReturn")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("btnEdit")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("btnCancel")).style.display = 'block';
    (<HTMLInputElement>document.getElementById("btnSave")).style.display = 'block';

  }

  buttonCancel() {

    this.selectDisabled = true;
    (<HTMLInputElement>document.getElementById("productName")).disabled = true;
    (<HTMLInputElement>document.getElementById("productStatus")).disabled = true;
    (<HTMLInputElement>document.getElementById("productCode")).disabled = true;
    (<HTMLInputElement>document.getElementById("visit")).disabled = true;
    (<HTMLInputElement>document.getElementById("productDescription")).disabled = true;
    (<HTMLInputElement>document.getElementById("account")).disabled = true;
    (<HTMLInputElement>document.getElementById("actionCode")).disabled = true;
    (<HTMLInputElement>document.getElementById("coverageCode")).disabled = true;
    (<HTMLInputElement>document.getElementById("insuranceCode")).disabled = true;
    (<HTMLInputElement>document.getElementById("productDetails")).disabled = true;
    (<HTMLInputElement>document.getElementById("invoiceMonths")).disabled = true;
    (<HTMLInputElement>document.getElementById("titleDetails")).style.display = 'block';
    (<HTMLInputElement>document.getElementById("titleEdit")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("subTitle")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("btnReturn")).style.display = 'block';
    (<HTMLInputElement>document.getElementById("btnEdit")).style.display = 'block';
    (<HTMLInputElement>document.getElementById("btnCancel")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("btnSave")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("productStatus")).style.color = '#202020';
    (<HTMLInputElement>document.getElementById("productStatus")).style.backgroundColor = '#E5E5E5';

    this.searchProduct(this.id);

  }

  accountOnKeyUp(event: any) {
    this.accountCodeUppercase = event.target.value.toUpperCase();
  }

  actionCodeOnKeyUp(event: any) {
    this.actionCodeUppercase = event.target.value.toUpperCase();
  }

  coverageCodeOnKeyUp(event: any) {
    this.coverageUppercase = event.target.value.toUpperCase();
  }

  insuranceCodeOnKeyUp(event: any) {
    this.insuranceCodeUppercase = event.target.value.toUpperCase();
  }

  statusChange(event: any) {
    this.selectedStatus = event.value;
  }

  months(months: any) {
    this.inputProductInvoiceMonths = this.onlyAcceptNumbers.formatNumber(months);
  }

  visit(visit: any) {
    this.inputProductVisit = this.onlyAcceptNumbers.formatNumber(visit);
  }

  buttonSave() {

    this.blockedUi = true;
    this.editionEnabled = true;

    this.inputProductName = (<HTMLInputElement>document.getElementById("productName")).value;
    this.inputProductStatus = this.selectedStatus;
    this.inputProductCode = (<HTMLInputElement>document.getElementById("productCode")).value;
    this.inputProductDescription = (<HTMLSelectElement>document.getElementById("productDescription")).value;

    this.inputProductAccountCode = (<HTMLInputElement>document.getElementById("account")).value;
    this.inputProductActionCode = (<HTMLInputElement>document.getElementById("actionCode")).value;
    this.inputProductCoverageCode = (<HTMLInputElement>document.getElementById("coverageCode")).value;
    this.inputProductInsuranceCode = (<HTMLInputElement>document.getElementById("insuranceCode")).value;
    this.inputProductProductDetails = (<HTMLInputElement>document.getElementById("productDetails")).value;

    if (this.inputProductName.length < 4) {

      this.inputProductName = null;
      this.blockedUi = false;
      this.editionEnabled = false;
    }
    if (this.inputProductStatus === null ||
      this.inputProductStatus === undefined) {

      this.inputProductStatus = null;
      this.blockedUi = false;
      this.editionEnabled = false;
    }
    if (this.inputProductCode.length <= 0) {

      this.inputProductCode = null;
      this.blockedUi = false;
      this.editionEnabled = false;
    }
    if (this.inputProductInvoiceMonths === undefined) {

      this.inputProductInvoiceMonths = Number((<HTMLInputElement>document.getElementById("invoiceMonths")).value);
    }
    if (this.inputProductVisit === undefined) {

      this.inputProductVisit = Number((<HTMLInputElement>document.getElementById("visit")).value);
    }
    if (this.inputProductVisit <= 0 ||
      isNaN(this.inputProductVisit)) {

      this.inputProductVisit = null;
      this.blockedUi = false;
      this.editionEnabled = false;
    }
    if (this.inputProductDescription.length <= 0) {

      this.inputProductDescription = null;
      this.blockedUi = false;
      this.editionEnabled = false;
    } else if (this.editionEnabled) {

      this.editedProduct.id = this.product.id;
      this.editedProduct.name = this.inputProductName;
      this.editedProduct.status = this.selectedStatus;
      this.editedProduct.country = this.product.country;
      this.editedProduct.code = this.inputProductCode;
      this.editedProduct.description = this.inputProductDescription;
      this.editedProduct.visit = this.inputProductVisit;
      this.editedProduct.months = this.inputProductInvoiceMonths;
      this.editedProduct.accountCode = this.inputProductAccountCode;
      this.editedProduct.actionCode = this.inputProductActionCode;
      this.editedProduct.coverageCode = this.inputProductCoverageCode;
      this.editedProduct.insuranceCode = this.inputProductInsuranceCode;
      this.editedProduct.details = this.inputProductProductDetails;
      this.editedProduct.updateUser = this.apsPermissionService.getUserCsid();

      this.productService.updateProduct(this.editedProduct).subscribe(
        (response: Product) => {

          this.blockedUi = false;
          this.searchProduct(this.editedProduct.id);
          this.buttonCancel();

          this.messageService.add({
            severity: 'success',
            summary: this.translateService.instant('TRANSLATE.SUCCESS'),
            detail: this.translateService.instant('TRANSLATE.SUCCESS.EDIT')
          });
        }, (err) => {

          this.blockedUi = false;
          this.searchProduct(this.editedProduct.id);

          if (err.status === 400) {

            this.messageService.add({
              severity: 'error',
              summary: this.translateService.instant('TRANSLATE.ERROR'),
              detail: this.translateService.instant('TRANSLATE.ERROR.PLEASE.REQUIRED.FIELDS')
            });
          } else if (err.status === 404) {

            this.messageService.add({
              severity: 'error',
              summary: this.translateService.instant('TRANSLATE.ERROR'),
              detail: this.translateService.instant('TRANSLATE.ERROR.SAVE.CHANGES')
            });

          } else if (err.status === 401) {

            this.messageService.add({
              severity: 'error',
              summary: this.translateService.instant('TRANSLATE.ERROR'),
              detail: this.translateService.instant('TRANSLATE.USER.WITHOUT.PERMISSION.REFRESH.PAGE')
            });

          } else {

            this.messageService.add({
              severity: 'error',
              summary: this.translateService.instant('TRANSLATE.ERROR'),
              detail: this.translateService.instant('TRANSLATE.ERROR.CONTACT.SYSTEM.ADMINISTRATOR')
            });
          }

        }
      );
    }

  }

  searchProduct(id: number) {
    this.productService.getProductById(id).subscribe(
      (response) => {
        this.product.id = response.id;
        this.product.name = response.name;
        this.product.country = response.country;
        this.countryNameResult = this.countryName.getCountryName(this.product.country);
        this.product.code = response.productCode;
        this.selectedStatus = ((response.status == "25") ? ConstantsFordProtect.PRODUCT_ACTIVE : ConstantsFordProtect.PRODUCT_INACTIVE);
        this.product.description = response.description;
        this.product.visit = response.visit;
        this.product.months = response.invoiceMonths;
        this.product.accountCode = response.accountNumber;
        this.product.actionCode = response.actionCode;
        this.product.coverageCode = response.coverageCode;
        this.product.insuranceCode = response.insuranceCode;
        this.product.createDate = response.createDate;
        this.productCreationDateformat = this.datePipe.transform(response.createDate, 'dd/MM/yyyy');
        this.product.details = response.productDetails;
        this.blockedUi = false;
      }
    )
  }

  private convertDateNeverTimezone(date: Date): Date {
    return new Date(date + 'T00:00:00.00');
  }

}
