import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { LazyLoadEvent, MessageService } from 'primeng';
import { ApsPermitedScreen } from 'src/app/models/aps-permission/aps-permited-screen';
import { ConstantsFordProtect } from 'src/app/models/constants/constants-ford-protect';
import { ContractCancel } from 'src/app/models/contract/contract-cancel';
import { FilterContractSearch } from 'src/app/models/contract/filter-contract-search';
import { FilterContractSearchResult } from 'src/app/models/contract/filter-contract-search-result';
import { FilterContractSearchResultSimplified } from 'src/app/models/contract/filter-contract-search-result-simplified';
import { FilterContractSearchResultTotalRecord } from 'src/app/models/contract/filter-contract-search-result-total-record';
import { SalesTypeFord } from 'src/app/models/contract/sales-type-ford';
import { Term } from 'src/app/models/contract/term';
import { TermGeneration } from 'src/app/models/contract/term-generation';
import { FileType } from 'src/app/models/enum/file-type';
import { OperationCode } from 'src/app/models/enum/operation-code';
import { StatusContract } from 'src/app/models/enum/status-contract';
import { ProductActive } from 'src/app/models/product/product-active';
import { ApsPermissionService } from 'src/app/services/aps-permission/aps-permission.service';
import { ContractService } from 'src/app/services/contract/contract.service';
import { ProductService } from 'src/app/services/product/product.service';
import { capitalizeWords } from 'src/assets/functions/capitalize_words';
import { fileTypeTerm, fileTypeTicket, listStatusType } from 'src/assets/functions/list-status-type';

@Component({
	selector: 'app-contract-search',
	templateUrl: './contract-search.component.html',
	styleUrls: ['./contract-search.component.scss']
})
export class ContractSearchComponent implements OnInit {

	pt = {
		firstDayOfWeek: 0,
		dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
		dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
		dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
		monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho',
			'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
		monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
		today: 'Hoje',
		clear: 'Limpar'
	};

	es = {
		firstDayOfWeek: 0,
		dayNames: ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sábado'],
		dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
		dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
		monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio',
			'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
		monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
		today: 'Hoy',
		clear: 'Limpar'
	};

	calendarLang = this.pt;

	statusFilter = [
		{ label: 'TRANSLATE.SELECT' },
		{ label: 'TRANSLATE.ACTIVE', value: StatusContract.ACTIVE },
		{ label: 'TRANSLATE.BLOCKED', value: StatusContract.BLOCKED },
		{ label: 'TRANSLATE.CANCELED', value: StatusContract.CANCELLED },
		{ label: 'TRANSLATE.FINISHED', value: StatusContract.FINISHED },
		{ label: 'TRANSLATE.CREATED', value: StatusContract.CREATED },
		{ label: 'TRANSLATE.PENDING.PAYMENT', value: StatusContract.PENDING_PAYMENT },
		{ label: 'TRANSLATE.PENDING.CANCELLATION', value: StatusContract.PENDING_CANCELLATION },
		{ label: 'TRANSLATE.PENDING.ACTIVATION', value: StatusContract.PENDING_ACTIVATION },
		{ label: 'TRANSLATE.PENDING.AMENDMENT', value: StatusContract.PENDING_AMENDMENT },
		{ label: 'TRANSLATE.WITH.ERROR', value: StatusContract.WITH_ERROR }
	];

	globalContractFilter = [
		{ label: 'TRANSLATE.SELECT', value: 0 },
		{ label: 'TRANSLATE.YES', value: 1 },
		{ label: 'TRANSLATE.NOT', value: 2 }
	];

	blockedUi: boolean = false;

	salesTypeFords: Array<SalesTypeFord> = [];
	salesTypeFord: SalesTypeFord;

	productActiveCountries: Array<ProductActive> = [];
	productActive: ProductActive;

	filterContractSearchResult: FilterContractSearchResult;
	filterContractSearch: FilterContractSearch;
	filterContractSearchResultList: Array<FilterContractSearchResult> = [];
	filterContractSearchResultTotalRecord: FilterContractSearchResultTotalRecord;
	contractSearchTotalRecord: number;
	isSubmitted: boolean = false;
	isSubmittedExcel: boolean = false;
	isPermission: boolean = false;
	prebContract: string;

	display: boolean = false;
	displayModalPdf: boolean = false;
	cancellationDateModal: string;
	selectedReason: string;
	idProductNameModal: string;
	chassiModal: string;
	customerNameModal: string;
	cancellationRequest: boolean = false;
	documentType: string;
	productKey: number;
	coverageKey: number;
	contractCancel: ContractCancel;
	contractId!: number | null;
	termGeneration: TermGeneration;
	termFileId!: number | null;
	termTypeFile!: number | null;
	ticketFileId!: number | null;
	ticketTypeFile!: number | null;
	fileNameType: string;
	isSubmittedFile: boolean = false;


	cancellationReason = [
		{ label: 'TRANSLATE.SELECT' },
		{ label: 'TRANSLATE.WITHDRAWAL', value: ConstantsFordProtect.WITHDRAWAL },
		{ label: 'TRANSLATE.THEFT', value: ConstantsFordProtect.THEFT },
		{ label: 'TRANSLATE.TOTAL.LOSS', value: ConstantsFordProtect.TOTAL_LOSS },
		{ label: 'TRANSLATE.OTHER', value: ConstantsFordProtect.OTHER }
	];

	constructor(
		private translateService: TranslateService,
		private messageService: MessageService,
		private router: Router,
		private apsPermissionService: ApsPermissionService,
		private contractService: ContractService,
		private productService: ProductService
	) {

		this.filterContractSearch = new FilterContractSearch();
		this.filterContractSearchResultTotalRecord = new FilterContractSearchResultTotalRecord();
		this.buttonClean();
	}

	//Revisar aqui - Doble carga de SalesTypeFord dropdown
	ngOnInit() {
		this.isPermission = this.apsPermissionService.permissionValidation(ApsPermitedScreen.screenContractSearch);

		if (this.isPermission) {
			if (this.contractService.getFilterContractSearch() != null) {
				this.filterContractSearch = this.contractService.getFilterContractSearch();
				this.searchSalesTypeFord();
				this.searchProductActiveCountry();
				this.buttonFilterSearch();
			} else {
				this.searchProductActiveCountry();
			}
			this.productActive = this.addProductActiveDefault();
			this.salesTypeFord = this.addSalesTypeFordDefault();
			this.searchSalesTypeFord();
			this.searchProductActiveCountry();
		}

		this.translateService.onLangChange.subscribe((langChange: any) => {
			switch (langChange.lang) {
				case "es-CL" || "es-CL":
					this.calendarLang = this.es;
					break;
				case "pt-BR":
					this.calendarLang = this.pt;
					break;
			}
		});

	}

	countries() {

		return [
			{ label: this.translateService.instant('TRANSLATE.ARGENTINA'), value: ConstantsFordProtect.ARG },
			{ label: this.translateService.instant('TRANSLATE.BRAZIL'), value: ConstantsFordProtect.BRA },
			{ label: this.translateService.instant('TRANSLATE.CHILE'), value: ConstantsFordProtect.CHL },
			{ label: this.translateService.instant('TRANSLATE.COLOMBIA'), value: ConstantsFordProtect.COL }
		];
	}

	filterValidation() {
		if (this.filterContractSearch.document === '' && this.filterContractSearch.vin === ''
			&& this.filterContractSearch.dealer === '' && this.filterContractSearch.contractOriginalNumber == null
			&& this.filterContractSearch.contractDateStart == null && this.filterContractSearch.contractDateEnd == null
			&& this.filterContractSearch.validityDateStart == null && this.filterContractSearch.validityDateEnd == null
			&& this.salesTypeFord.id == 0 && this.productActive.id == 0 && this.filterContractSearch.contractStatus == 0
			&& !this.filterContractSearch.countries && this.filterContractSearch.globalContract == 0) {
			this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Filtro não selecionado.' });
		} else if (!this.filterContractSearch.countries || this.filterContractSearch.countries.length == 0) {
			this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Selecione o(s) país(es).' });
		} else {
			this.buttonFilterSearch();
		}
	}

	buttonFilterSearch() {
		this.isSubmitted = true;
		this.blockedUi = true;
		this.filterContractSearch.salesTypeFordId = this.salesTypeFord.id;
		this.filterContractSearch.productId = this.productActive.id;
		this.filterContractSearch.operation = OperationCode.CAR;
		this.filterContractSearch.statusType = listStatusType();

		this.contractService.contractSearch(this.filterContractSearch)
			.subscribe((response: FilterContractSearchResultTotalRecord) => {
				this.filterContractSearchResultTotalRecord = response;
				this.isSubmitted = false;
				this.blockedUi = false;
			}, (err) => {

				if (err.status === 401) {

					this.messageService.add({
						severity: 'error',
						summary: this.translateService.instant('TRANSLATE.ERROR'),
						detail: this.translateService.instant('TRANSLATE.USER.WITHOUT.PERMISSION.REFRESH.PAGE')
					});

				} else {

					this.messageService.add({
						severity: 'error',
						summary: this.translateService.instant('TRANSLATE.ERROR'),
						detail: this.translateService.instant('TRANSLATE.ERROR.CONTACT.SYSTEM.ADMINISTRATOR')
					});
				}

				this.isSubmitted = false;
				this.blockedUi = false;
			});
	}

	loadData(event: LazyLoadEvent) {
		if (event.first > 0) {
			this.filterContractSearch.numberPage = (event.first / event.rows);
			this.filterValidationLazyLoad();
		} else {
			this.filterContractSearch.numberPage = event.first;
			this.filterValidationLazyLoad();
		}
	}

	filterValidationLazyLoad() {
		if (this.filterContractSearch.document === '' && this.filterContractSearch.vin === ''
			&& this.filterContractSearch.dealer === '' && this.filterContractSearch.contractOriginalNumber === ''
			&& this.filterContractSearch.contractDateStart == null && this.filterContractSearch.contractDateEnd == null
			&& this.filterContractSearch.validityDateStart == null && this.filterContractSearch.validityDateEnd == null
			&& this.salesTypeFord.id == 0 && this.productActive.id == 0 && this.filterContractSearch.contractStatus == 0
			// alterei
			&& !this.filterContractSearch.countries && this.filterContractSearch.globalContract == 0) {

		} else {
			this.buttonFilterSearch();
		}
	}

	buttonClean() {
		this.filterContractSearch = new FilterContractSearch();
		this.filterContractSearch.document = '';
		this.filterContractSearch.vin = '';
		this.filterContractSearch.dealer = '';
		this.filterContractSearch.countries = null;
		this.filterContractSearch.contractNumber = null;
		this.filterContractSearch.contractDateStart = null;
		this.filterContractSearch.contractDateEnd = null;
		this.filterContractSearch.globalContract = 0;
		this.filterContractSearch.contractStatus = 0;
		this.productActive = this.addProductActiveDefault();
		this.salesTypeFord = this.addSalesTypeFordDefault();
	}

	exportExcel() {
		if (this.filterContractSearch.document === '' && this.filterContractSearch.vin === ''
			&& this.filterContractSearch.dealer === '' && this.filterContractSearch.contractOriginalNumber === ''
			&& this.filterContractSearch.contractDateStart == null && this.filterContractSearch.contractDateEnd == null
			&& this.filterContractSearch.validityDateStart == null && this.filterContractSearch.validityDateEnd == null
			&& this.salesTypeFord.id <= 0 && this.productActive.id <= 0 && this.filterContractSearch.contractStatus <= 0
			&& !this.filterContractSearch.countries && this.filterContractSearch.globalContract == 0) {

			this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Filtro não selecionado.' });

		}
		else if (!this.filterContractSearch.countries || this.filterContractSearch.countries.length == 0) {

			this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Selecione o(s) país(es).' });

		}
		else {
			this.isSubmittedExcel = true;
			this.blockedUi = true;

			this.contractService.contractSearchExcel(this.filterContractSearch)
				.subscribe((response: Array<FilterContractSearchResult>) => {

					this.importXlsxFromArray(response);

					this.isSubmittedExcel = false;
					this.blockedUi = false;
				}, (err) => {
					if (err.status == 500) {
						this.messageService.add({
							severity: 'error', summary: this.translateService.instant('TRANSLATE.ERROR'),
							detail: this.translateService.instant('TRANSLATE.EXCEL.CANNOT.BE.EXPORTED')
						});
					}

					this.isSubmittedExcel = false;
					this.blockedUi = false;
				});
		}
	}

	private importXlsxFromArray(array: Array<FilterContractSearchResult>): void {
		import("xlsx").then(xlsx => {
			const worksheet = xlsx.utils.json_to_sheet(array);
			const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
			const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
			this.saveAsExcelFile(excelBuffer, "contracts");
		});
	}

	private saveAsExcelFile(buffer: any, fileName: string): void {
		let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
		let EXCEL_EXTENSION = '.xlsx';
		const data: Blob = new Blob([buffer], {
			type: EXCEL_TYPE
		});
		saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
	}

	buttonEdit(filterContractSearchResultSimplified: FilterContractSearchResultSimplified) {
		this.contractService.setFilterContractSearch(this.filterContractSearch);
		this.contractService.setFilterContractSearchResultSimplified(filterContractSearchResultSimplified);
		this.router.navigate(['contract-edit']);
	}

	statusName(statusId: number): string {
		return this.statusFilter.find(status => status.value == statusId).label;
	}

	private searchSalesTypeFord() {
		this.contractService.searchSalesTypeFord()
			.subscribe((response: Array<SalesTypeFord>) => {
				this.salesTypeFords.push(this.addSalesTypeFordDefault());
				this.salesTypeFords = this.translateSalesTypeFords(this.salesTypeFords.concat(response));


				if (this.filterContractSearch.salesTypeFordId > 0) {
					this.salesTypeFord = this.salesTypeFords.find(salesTypeFord => salesTypeFord.id == this.filterContractSearch.salesTypeFordId);
				} else {
					this.salesTypeFord = this.addSalesTypeFordDefault();
				}
			}
				, (err) => {
					if (err.status == 500) {
						this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Tipo de venda não encontrado.' });
					}
				});
	}

	private translateSalesTypeFords(salesTypeFordArray: SalesTypeFord[]): SalesTypeFord[] {

		for (var i = 0; i < salesTypeFordArray.length; i++) {
			switch (salesTypeFordArray[i].description) {
				case "Varejo":
					salesTypeFordArray[i].description = "TRANSLATE.RETAIL"
					break;
				case "Direta":
					salesTypeFordArray[i].description = "TRANSLATE.DIRECT"
					break;
				case "Governo":
					salesTypeFordArray[i].description = "TRANSLATE.FLEET"
					break;
				case "Free Contract":
					salesTypeFordArray[i].description = "TRANSLATE.FREE.CONTRACT"
					break;
				case "Frota Interna":
					salesTypeFordArray[i].description = "TRANSLATE.INTERNAL.FLEET"
					break;
			}
		}
		return salesTypeFordArray;
	}

	private addSalesTypeFordDefault(): SalesTypeFord {
		let salesTypeFord: SalesTypeFord = new SalesTypeFord();
		salesTypeFord.id = 0;
		salesTypeFord.description = 'TRANSLATE.SELECT';

		return salesTypeFord;
	}

	private searchProductActiveCountry() {

		if (this.filterContractSearch.countries != null) {
			let count = 0;

			if (this.filterContractSearch.countries.length > 0) {
				this.productService.getProductActiveCodeCountries(this.filterContractSearch.countries)
					.subscribe((response: Array<ProductActive>) => {

						this.productActiveCountries = response;
						if (count < 1) {
							this.productActiveCountries.push(this.addProductActiveDefault());
							//	repete os Ids nessa linha
							this.productActiveCountries = this.productActiveCountries.concat(response);
						}

						if (this.filterContractSearch.productId > 0) {
							this.productActive = this.productActiveCountries.find(product => product.id == this.filterContractSearch.productId);

						} else {
							if (count < 1) {
								this.productActive = this.addProductActiveDefault();
							}
						}
					}
						, (err) => {

							if (err.status == 400) {
								this.messageService.add({
									severity: 'error',
									summary: this.translateService.instant('TRANSLATE.ERROR'),
									detail: this.translateService.instant('TRANSLATE.PRODUCTS.NOT.FOUND')
								});
							} else if (err.status === 401) {

								this.messageService.add({
									severity: 'error',
									summary: this.translateService.instant('TRANSLATE.ERROR'),
									detail: this.translateService.instant('TRANSLATE.USER.WITHOUT.PERMISSION.REFRESH.PAGE')
								});

							} else {

								this.messageService.add({
									severity: 'error',
									summary: this.translateService.instant('TRANSLATE.ERROR'),
									detail: this.translateService.instant('TRANSLATE.ERROR.CONTACT.SYSTEM.ADMINISTRATOR')
								});
							}

						});
				count++;
			} else {
				this.productActiveCountries = [];
			}
		}
	}

	private addProductActiveDefault(): ProductActive {
		let productActive: ProductActive = new ProductActive();
		productActive.id = 0;
		productActive.description = 'Selecione';
		productActive.name = 'Selecione';
		productActive.code = 'Selecione';

		return productActive;
	}

	countryChange(event: any) {
		this.searchProductActiveCountry();
	}

	rangeDateLabel(): string {
		let date: Date = new Date();
		let dateLabelYear: number = date.getFullYear() + 50;
		return '1970:' + dateLabelYear;
	}

	showDialog(contractKey: number, productKey: number, coverageCategoryKey: number, status: number) {

		this.display = true;
		if (status === StatusContract.PENDING_CANCELLATION) {

			this.cancellationRequest = true;
		} else {

			if (this.filterContractSearchResultTotalRecord != null) {

				this.contractService.searchContractId(contractKey, productKey, coverageCategoryKey)
					.subscribe((response: FilterContractSearchResult) => {
						this.filterContractSearchResult = response;
						this.idProductNameModal = this.filterContractSearchResult.productKey + " - " + this.filterContractSearchResult.productName;
						this.chassiModal = this.filterContractSearchResult.vin;
						this.customerNameModal = this.filterContractSearchResult.customerName + " " + this.filterContractSearchResult.customerLastName;
						this.customerNameModal = capitalizeWords(this.customerNameModal);
						this.contractId = contractKey;
						this.productKey = productKey;
						this.coverageKey = coverageCategoryKey;
					},
						(err) => {

							if (err.status === 400 || err.status === 404 || err.status === 500) {

								this.messageService.add({
									severity: 'error',
									summary: this.translateService.instant('TRANSLATE.ERROR'),
									detail: this.translateService.instant('TRANSLATE.INVALID.CONTRACT.SEARCH.CONTACT.ADM')
								});

							} else if (err.status === 401) {

								this.messageService.add({
									severity: 'error',
									summary: this.translateService.instant('TRANSLATE.ERROR'),
									detail: this.translateService.instant('TRANSLATE.USER.WITHOUT.PERMISSION.REFRESH.PAGE')
								});

							} else {

								this.messageService.add({
									severity: 'error',
									summary: this.translateService.instant('TRANSLATE.ERROR'),
									detail: this.translateService.instant('TRANSLATE.ERROR.CONTACT.SYSTEM.ADMINISTRATOR')
								});
							}

						});
			}
		}
	}

	reasonChange(event: any) {
		this.selectedReason = event.value;
	}

	cancelRequestButton() {

		this.blockedUi = true;

		if (this.cancellationDateModal === undefined || this.cancellationDateModal === null) {

			this.cancellationDateModal = null;
			this.blockedUi = false;
		}
		if (this.selectedReason === undefined || this.selectedReason === null) {

			this.selectedReason = null;
			this.blockedUi = false;

		} else {

			const fecha = new Date(this.cancellationDateModal);
			const year = fecha.getFullYear();
			const month = fecha.getMonth() + 1;
			const day = fecha.getDate();
			const parsedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

			let requestedEndDate = parsedDate;
			this.contractService.determineCancelValues(this.contractId, this.productKey, this.coverageKey, requestedEndDate).subscribe(cancelBody => {
				this.contractService.cancelContract(cancelBody).subscribe(success => {
					this.blockedUi = false;
					if (success) {
						// Criar o Serviço de download do termo e usar os dois itens abaixo
						this.cleanFilters();
						this.messageService.add({
							severity: 'success',
							summary: this.translateService.instant('TRANSLATE.SUCCESS'),
							detail: this.translateService.instant('TRANSLATE.CANCELLATION.REQUEST.SUCCESSFUL')
						});
					}
					else {
						this.messageService.add({
							severity: 'error',
							summary: this.translateService.instant('TRANSLATE.ERROR'),
							detail: this.translateService.instant('TRANSLATE.UNABLE.TO.REQUEST.CANCELLATION')
						});
					}
				});
			});
			
		}

	}

	cleanFilters() {
		this.display = false;
		this.cancellationDateModal = undefined;
		this.selectedReason = undefined;
	}

	//MODAL CONTROL - download PDF
	retrievingContractId(contractNumber: number) {
		this.contractId = contractNumber;

		this.searchingTermByContractIdAndFileType(this.contractId);
		this.searchingForTicketByContractIdAndFileType(this.contractId);
	}

	searchingTermByContractIdAndFileType(contractId: number) {

		this.contractService.searchByContractIdAndFileType(contractId, fileTypeTerm())
			.subscribe((response: Term) => {

				this.termFileId = response.fileId;
				this.termTypeFile = response.fileType;
			});

	}

	searchingForTicketByContractIdAndFileType(contractId: number) {

		this.contractService.searchByContractIdAndFileType(contractId, fileTypeTicket())
			.subscribe((response: Term) => {

				this.ticketFileId = response.fileId;
				this.ticketTypeFile = response.fileType;
			});

	}

	showModalPdfBra() {

		if (this.contractId !== null && this.ticketFileId !== null &&
			this.contractId > 0 && this.ticketFileId > 0) {

			this.displayModalPdf = true;

		} else {

			this.messageService.add({
				severity: 'error',
				summary: this.translateService.instant('TRANSLATE.ERROR'),
				detail: this.translateService.instant('TRANSLATE.CONTRACT.NOT.FOUND')
			});
		}
	}

	opDocument(event: any) {
		this.documentType = event.value;
	}

	downloadPdf() {

		if (this.documentType === undefined || this.documentType === null) {

			this.documentType = null;
		} else {

			if (this.documentType === this.translateService.instant('TRANSLATE.TERM')) {

				this.isSubmittedFile = true;

				this.termGeneration = new TermGeneration(this.contractId, this.termFileId);

				this.contractService.createFileTerm(this.termGeneration)
					.subscribe((blob) => {

						const fileName = this.getFileName(this.termTypeFile);
						this.forceDownload(blob, fileName);


						setTimeout(() => {

							this.isSubmittedFile = false;
						}, 1000);

						this.displayModalPdf = false;
						this.clearModalPdf();

						this.messageService.add({
							severity: 'success',
							summary: this.translateService.instant('TRANSLATE.SUCCESS'),
							detail: this.translateService.instant('TRANSLATE.DOWNLOAD.TERM.SUCCESSFULLY')
						});

					}, (error) => {

						if (error.status === 400 || error.status === 404 || error.status === 500) {

							this.messageService.add({
								severity: 'error',
								summary: this.translateService.instant('TRANSLATE.ERROR'),
								detail: this.translateService.instant('TRANSLATE.DOWNLOAD.COULD.NOT.BE.COMPLETED')
							});

						} else if (error.status === 401) {

							this.messageService.add({
								severity: 'error',
								summary: this.translateService.instant('TRANSLATE.ERROR'),
								detail: this.translateService.instant('TRANSLATE.USER.WITHOUT.PERMISSION.REFRESH.PAGE')
							});

						} else {

							this.messageService.add({
								severity: 'error',
								summary: this.translateService.instant('TRANSLATE.ERROR'),
								detail: this.translateService.instant('TRANSLATE.ERROR.CONTACT.SYSTEM.ADMINISTRATOR')
							});
						}

						setTimeout(() => {

							this.isSubmittedFile = false;
						}, 1000);

					});

			} else if (this.documentType === this.translateService.instant('TRANSLATE.TICKET')) {

				this.isSubmittedFile = true;

				this.contractService.getPdfFile(this.contractId, this.ticketFileId)
					.subscribe({
						next: (blob) => {

							const fileName = this.getFileName(this.ticketTypeFile);
							this.forceDownload(blob, fileName);

							setTimeout(() => {

								this.isSubmittedFile = false;
							}, 1000);

							this.displayModalPdf = false;
							this.clearModalPdf();

							this.messageService.add({
								severity: 'success',
								summary: this.translateService.instant('TRANSLATE.SUCCESS'),
								detail: this.translateService.instant('TRANSLATE.DOWNLOAD.TICKET.SUCCESSFULLY')
							});

						}, error: (error) => {

							if (error.status === 400 || error.status === 404 || error.status === 500) {

								this.messageService.add({
									severity: 'error',
									summary: this.translateService.instant('TRANSLATE.ERROR'),
									detail: this.translateService.instant('TRANSLATE.DOWNLOAD.COULD.NOT.BE.COMPLETED')
								});

							} else if (error.status === 401) {

								this.messageService.add({
									severity: 'error',
									summary: this.translateService.instant('TRANSLATE.ERROR'),
									detail: this.translateService.instant('TRANSLATE.USER.WITHOUT.PERMISSION.REFRESH.PAGE')
								});

							} else {

								this.messageService.add({
									severity: 'error',
									summary: this.translateService.instant('TRANSLATE.ERROR'),
									detail: this.translateService.instant('TRANSLATE.ERROR.CONTACT.SYSTEM.ADMINISTRATOR')
								});
							}

							setTimeout(() => {

								this.isSubmittedFile = false;
							}, 1000);

						}

					});

			} else {
				this.documentType = null;
			}
		}

	}

	downloadFileTerm() {

		if (this.contractId !== null && this.termFileId !== null &&
			this.contractId > 0 && this.termFileId > 0) {

			this.termGeneration = new TermGeneration(this.contractId, this.termFileId);

			this.isSubmittedFile = true;

			this.contractService.createFileTerm(this.termGeneration)
				.subscribe((blob) => {

					const fileName = this.getFileName(this.termTypeFile);
					this.forceDownload(blob, fileName);

					setTimeout(() => {

						this.isSubmittedFile = false;
					}, 1000);

					this.displayModalPdf = false;
					this.clearModalPdf();

					this.messageService.add({
						severity: 'success',
						summary: this.translateService.instant('TRANSLATE.SUCCESS'),
						detail: this.translateService.instant('TRANSLATE.DOWNLOAD.TERM.SUCCESSFULLY')
					});

				}, (error) => {

					if (error.status === 400 || error.status === 404 || error.status === 500) {

						this.messageService.add({
							severity: 'error',
							summary: this.translateService.instant('TRANSLATE.ERROR'),
							detail: this.translateService.instant('TRANSLATE.DOWNLOAD.COULD.NOT.BE.COMPLETED')
						});

					} else if (error.status === 401) {

						this.messageService.add({
							severity: 'error',
							summary: this.translateService.instant('TRANSLATE.ERROR'),
							detail: this.translateService.instant('TRANSLATE.USER.WITHOUT.PERMISSION.REFRESH.PAGE')
						});

					} else {

						this.messageService.add({
							severity: 'error',
							summary: this.translateService.instant('TRANSLATE.ERROR'),
							detail: this.translateService.instant('TRANSLATE.ERROR.CONTACT.SYSTEM.ADMINISTRATOR')
						});
					}

					setTimeout(() => {

						this.isSubmittedFile = false;
					}, 1000);

				});

		} else {

			this.messageService.add({
				severity: 'error',
				summary: this.translateService.instant('TRANSLATE.ERROR'),
				detail: this.translateService.instant('TRANSLATE.CONTRACT.NOT.FOUND')
			});
		}

	}

	getFileName(typeFile: number): string {

		switch (typeFile) {

			case FileType.PURCHASE_TERM:
				return FileType.PURCHASE_TERM_DESCRIPTION;

			case FileType.LICENSE_TERM:
				return FileType.LICENSE_TERM_DESCRIPTION;

			case FileType.TICKET:
				return FileType.TICKET_DESCRIPTION;

			case FileType.UPDATE_TICKET:
				return FileType.UPDATE_TICKET_DESCRIPTION;

			case FileType.CANCELLATION_TICKET:
				return FileType.CANCELLATION_TICKET_DESCRIPTION;

			case FileType.CONTRACT_SUCCESS_CO:
				return FileType.CONTRACT_SUCCESS_CO_DESCRIPTION;

			case FileType.CONTRACT_SUCCESS_CH:
				return FileType.CONTRACT_SUCCESS_CH_DESCRIPTION;

			default:
				return 'arquivo_generico.pdf';

		}

	}

	forceDownload(blob: Blob, fileName: string) {

		const url = window.URL.createObjectURL(blob);
		const link = document.createElement("a");
		link.href = url;
		link.download = fileName;
		link.click();
		window.URL.revokeObjectURL(url);
	}

	clearModalPdf() {

		this.displayModalPdf = false;
		this.documentType = '';

		this.contractId = null;
		this.termFileId = null;
		this.termTypeFile = null;
		this.termGeneration = null;
		this.ticketFileId = null;
		this.ticketTypeFile = null;
		this.fileNameType = '';
	}

}
